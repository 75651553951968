<template>
  <div>
    <!-- Modal dialog: Remove device -->
    <v-dialog 
      transition="dialog-top-transition"
      v-model="dialog" 
      width="500"
      @keydown.esc="no" 
      persistent
    >
      <v-card-title class="dialogTitleClass bg-dialog-error"><v-icon class="mr-2 icon-error">mdi-delete-forever</v-icon>{{ title }}</v-card-title>
      <v-card-text v-show="!!message" class="dialogTextClass" v-html="message"></v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col>
              <v-btn elevation="0" v-if="!options.noconfirm" class="btn-red" @click.native="yes">
                <span class="s-text-white-bold">SI</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn elevation="0" v-if="!options.noconfirm" class="btn-back" @click.native="no">
                <span class="s-text-white-bold">NO</span>
              </v-btn>              
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-dialog>   
    <!-- LOADER SCREEN -->
    <v-overlay :value="waitLoadScreen">
      <v-dialog v-model="waitLoadScreen" hide-overlay persistent width="300">
        <v-card class="pa-5" color="#00627c">
          <v-card-text>
            <div class="pb-4"><span class="text-dialog-wait">{{ this.textLoader }}</span></div>
            <v-progress-linear indeterminate color="#90e6fd" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>                    
    </v-overlay>  
  </div>
</template>

<script>
  import config from '../config.json';
  import axios from "axios";

  export default {
    name: "dialogLogout",
    data() {
      return {
        urlCo2Devices: config.host + config.apiUrl + config.urlDevices,
        userLocal: undefined,
        waitLoadScreen: false,
        textLoader: '',
        dialog: false,
        resolve: null,
        reject: null,
        info: null,
        message: null,
        title: null,
        options: {
          noconfirm: false,
        },
      };
    },
    mounted(){
      this.userLocal = JSON.parse(window.localStorage.getItem('infoUser'));
    },
    methods: {
      open(info, title, message, options) {
        this.dialog = true;
        this.info = info;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });
      },
      async yes() {
        this.resolve(false);
        this.dialog = false;
        this.waitLoadScreen = true;
        this.textLoader = 'Eliminando...'
        await axios.delete(this.urlCo2Devices + this.info.id,         
        {
          headers:{
            "Content-Type": "application/json",
            "Authorization": `Bearer ${this.userLocal.token}`
          }
        })
        .then(authoritation => {
            this.$emit("respondRemoveDevice", this.info);
            return authoritation;
        })
        .catch(function(error){
            console.error(error);
            return undefined;
        });
        this.waitLoadScreen = false;
        
      },
      
      no() {
        this.resolve(false);
        this.dialog = false;
      },
    },
  };
</script>